.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ab001c;
}
.button:active {
  background-color: #780014;
}
.content {
  margin-top: 30px;
}
.content--1 .area .pure:not(.fold),
.content--3 .area .unit:not(.fold) {
  padding-bottom: 34px;
}
.content--1 .area .seam:not(.fold),
.content--1 .area .flat:not(.fold),
.content--2 .area .seam:not(.fold),
.content--2 .area .flat:not(.fold) {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .edge:not(.fold),
.content--2 .area .edge:not(.fold) {
  margin-bottom: -14px;
}
.unit.fold + .unit.fold {
  margin-top: -21px;
}
.content--1 .area .fold.pure div.less,
.content--3 .area .fold.pure div.less,
.content--1 .area .fold.seam div.less,
.content--3 .area .fold.seam div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.pure div.more,
.content--3 .area .fold.pure div.more,
.content--1 .area .fold.seam div.more,
.content--3 .area .fold.seam div.more {
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.less,
.content--3 .area .fold.flat div.less,
.content--1 .area .fold.edge div.less,
.content--3 .area .fold.edge div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.more,
.content--3 .area .fold.flat div.more,
.content--1 .area .fold.edge div.more,
.content--3 .area .fold.edge div.more {
  padding-bottom: 8px;
}
.content--1 .area,
.content--3 .area {
  width: 100%;
}
.content--1 .area > .unit,
.content--3 .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.content--1 .area .cb-album .body,
.content--3 .area .cb-album .body,
.content--1 .area .head,
.content--3 .area .head,
.content--1 .area .foot,
.content--3 .area .foot,
.content--1 .area .part,
.content--3 .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim,
.content--3 .area > .slim {
  width: 46%;
}
.content--1 .area > .slim .head,
.content--3 .area > .slim .head,
.content--1 .area > .slim .foot,
.content--3 .area > .slim .foot,
.content--1 .area > .slim .part,
.content--3 .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim.cb-album .body,
.content--3 .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--1 .area > .slim .tiny,
.content--3 .area > .slim .tiny {
  width: 100%;
}
.content--1 .area,
.content--3 .area {
  width: 104%;
  margin-left: -2%;
}
.content--1 .area .unit.pure.wide .body,
.content--3 .area .unit.pure.wide .body {
  width: 104%;
  margin-left: -2%;
}
.content--1 .area .unit.pure.wide .part,
.content--3 .area .unit.pure.wide .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
.content--1 .area .unit.pure.wide .part.tiny,
.content--3 .area .unit.pure.wide .part.tiny {
  width: 46%;
}
.content--1 .area .fold,
.content--3 .area .fold {
  width: 96%;
}
.content--1 .area .fold .body,
.content--3 .area .fold .body {
  width: 104%;
  margin-left: -2%;
}
.content--1 .area .fold .part,
.content--3 .area .fold .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96% !important;
}
.content--1 .area .fold .part.tiny,
.content--3 .area .fold .part.tiny {
  width: 46% !important;
}
/*# sourceMappingURL=./screen-medium.css.map */